import React, { useState } from 'react';
import { Button, Alert } from '@mui/material';
import { useGetAdminToyDeals } from '@hooks/useToyDeals';
import { useBulkAddToys } from '@hooks/useSuggestions';
import { useGetDealSummaryAdminEnabled } from '@hooks/useGetDealSummary';

const AddToToyDealsButton = ({ ASIN }: { ASIN: string }) => {
  const [isAdded, setIsAdded] = useState(false);
  const { data: toyDeals, isLoading } = useGetAdminToyDeals();
  const { mutate: bulkAddToys, isLoading: isLoadingAdd } = useBulkAddToys();
  const { data: dealSummary, isLoading: isLoadingDealSummary } =
    useGetDealSummaryAdminEnabled(ASIN);

  const ASINExists = (toyDeals || []).some((deal) => deal.ASIN === ASIN);
  const price = dealSummary?.dealThreshold || null;

  const styles = {
    marginBottom: '8px'
  };

  if (isLoading || isLoadingDealSummary) {
    return (
      <Button sx={styles} variant="contained" color="primary" disabled>
        Checking if exists...
      </Button>
    );
  }

  if (isLoadingAdd) {
    return (
      <Button variant="contained" color="primary" sx={styles} disabled>
        Adding...
      </Button>
    );
  }

  if (ASINExists) {
    return (
      <Alert variant="outlined" severity="success" sx={styles}>
        Already in Toy Deals
      </Alert>
    );
  }

  if (isAdded) {
    return (
      <Alert variant="outlined" severity="success" sx={styles}>
        Added to toy Deals
      </Alert>
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      sx={styles}
      onClick={() => {
        bulkAddToys({ ASINs: [{ ASIN, price }], shouldAddMainDB: false });
        setIsAdded(true);
      }}
    >
      Add to Toy Deals
    </Button>
  );
};

export default AddToToyDealsButton;
