export interface AdminButtonProps {
  ASIN: string;
  onCloseCallback?: () => void;
}

export const parseCountFromString = (
  inputStringVal: string | null
): number | null => {
  if (!inputStringVal) {
    return null;
  }
  const regexes = [
    / (\d+) (count|ct|rolls|bars)\b/i,
    /\(Pack of (\d+)\)\b/i,
    / (\d+) (pack)\b/i,
    /\((\d+) Count\)/,
    /\((\d+) ct\)/,
    / (\d+) count/,
    /(\d+)-count/,
    /(\d+)-Count/,
    /\(pack of (\d+)\)/,
    /\((\d+)-pack\)/,
    /\((\d+) bars\)/,
    /(\d+) cans/,
    /Pack of (\d+)/,
    /pack of (\d+)/,
    /Pack Of (\d+)/,
    /(\d+)-Pack/,
    /(\d+)pk/
  ];

  for (let i = 0; i < regexes.length; i++) {
    const regex = regexes[i];
    const match = inputStringVal.match(regex);

    if (match && match[1]) {
      return parseInt(match[1], 10);
    }
  }

  return null;
};

export const qtyTermMap = [
  {
    search: 'battery',
    term: 'battery'
  },
  {
    search: 'diaper',
    term: 'diaper'
  },
  {
    search: 'training pant',
    term: 'training pant'
  },
  {
    search: ' bar',
    term: 'bar'
  },
  {
    search: 'k-cup',
    term: 'k-cup'
  },
  {
    search: 'ip balm',
    term: 'lip balm'
  },
  {
    search: ' blade',
    term: 'blade'
  },
  {
    search: 'LaCroix',
    term: 'can'
  },
  {
    search: ' tubes',
    term: 'tube'
  },
  {
    search: ' cans',
    term: 'can'
  }
];
