import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  MenuItem,
  ListItemIcon,
  ListItemText,
  DialogContentText
} from '@mui/material';
import ToysIcon from '@mui/icons-material/Toys';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';
import { stopPropagationForTab } from '@utils/index';
import { useMoveToToyDeals } from '@hooks/useUpdateDeal';
import SeeStatsContent from '../SeeStatsContent';
import { AdminButtonProps } from '../../utils';
import AddToToyDealsButton from './components/AddToToyDealsButton';

const MoveToToyDeals = ({
  ASIN,
  onCloseCallback = () => {}
}: AdminButtonProps) => {
  const { showMessage } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const { mutate: moveToyDeals, isLoading: isUpdating } = useMoveToToyDeals();

  const { data: user } = useGetUserData();

  const handleModalClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  const renderMoveToyDeal = () => {
    if (modalOpen) {
      return (
        <Dialog
          open
          onClose={handleModalClose}
          fullWidth
          onKeyDown={stopPropagationForTab}
        >
          <DialogTitle>Move to toy deals?</DialogTitle>
          <DialogContent>
            <AddToToyDealsButton ASIN={ASIN} />
            <DialogContentText>{ASIN}</DialogContentText>
            <SeeStatsContent ASIN={ASIN} />
          </DialogContent>
          <DialogActions>
            <Button disabled={isUpdating} onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              disabled={isUpdating}
              onClick={() => {
                moveToyDeals(
                  { ASIN },
                  {
                    onSuccess: () => {
                      showMessage('Deal moved to toy deals');
                      handleModalClose();
                    }
                  }
                );
              }}
              variant="contained"
              color="primary"
            >
              {isUpdating ? 'Moving...' : 'Move'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };

  return (
    <>
      {renderMoveToyDeal()}
      <MenuItem
        key="admin-move-to-toy-deals"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <ListItemIcon>
          <ToysIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Move to Toy Deals</ListItemText>
      </MenuItem>
    </>
  );
};

export default MoveToToyDeals;
