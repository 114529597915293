/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios, { AxiosInstance } from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import config from '@configFile';
import { DealPostType, ToyDeal, SecondaryCategoryType } from '../types';
import queryClient from '../queryClient';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX // Replace with your base URL
});

export function useDeleteToyDeal() {
  interface RequestData {
    ASIN: string;
    forever?: boolean;
  }

  const mutationFn = async ({
    ASIN,
    forever = false
  }: RequestData): Promise<null> => {
    return axiosInstance.post(`/api/dashboard/delete-toy-deal`, {
      ASIN,
      forever
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error updating deal');
    },
    onSuccess: () => {
      queryClient.refetchQueries('toy-deals-admin');
    }
  });
}

export function useTransferToyDeal() {
  interface RequestData {
    ASIN: string;
    price: number;
    shouldDelete: boolean;
  }

  const mutationFn = async ({
    ASIN,
    price,
    shouldDelete
  }: RequestData): Promise<null> => {
    return axiosInstance.post(`/api/dashboard/add-toy-to-deals`, {
      ASIN,
      threshold: price,
      shouldDelete
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error updating deal');
    },
    onSuccess: () => {
      queryClient.refetchQueries('toy-deals-admin');
    }
  });
}

export function useGetToyDeal(ASIN: string): UseQueryResult<DealPostType> {
  const queryFn = async (): Promise<DealPostType> => {
    const result = await axiosInstance.get(
      `/api/dashboard/get-toy-deal-summary-admin?ASIN=${ASIN}`
    );

    return result.data;
  };

  return useQuery<DealPostType, Error>(
    `toy-deal-summary-admin-${ASIN}`,
    () => queryFn(),

    {
      onError: () => {
        console.error('Something went wrong while fetching the toy deal info');
      },
      enabled: false
    }
  );
}

export function useToyDeals(): UseQueryResult<DealPostType[]> {
  const queryToyDeals = async (): Promise<DealPostType[]> => {
    const result = await axiosInstance.get(`/api/get-daily-toy-deals`);

    return result.data;
  };

  return useQuery<DealPostType[], Error>(
    `daily-toy-deals`,
    () => queryToyDeals(),
    {
      onError: () => {
        console.error('Something went wrong while fetching the top deals');
      }
    }
  );
}

export function useGetLiveToyDeals(): UseQueryResult<DealPostType[]> {
  const queryFn = async (): Promise<DealPostType[]> => {
    const result = await axiosInstance.get(`/api/get-all-toy-deals`);

    return result.data;
  };

  return useQuery<DealPostType[], Error>(`toy-deals-live`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the top deals');
    }
  });
}

export function useGetAdminToyDeals(): UseQueryResult<ToyDeal[]> {
  const queryToyDeals = async (): Promise<ToyDeal[]> => {
    const result = await axiosInstance.get(`/api/dashboard/get-toy-deals`);

    return result.data;
  };

  return useQuery<ToyDeal[], Error>(`toy-deals-admin`, () => queryToyDeals(), {
    onError: () => {
      console.error('Something went wrong while fetching the top deals');
    }
  });
}

export function useGetSecondaryCategoriesToys(): UseQueryResult<
  SecondaryCategoryType[]
> {
  const queryFn = async (): Promise<
    Array<{
      second_category: string;
      count: number;
    }>
  > => {
    const result = await axiosInstance.get(
      `/api/dashboard/get-secondary-categories-toys`
    );

    return result.data;
  };

  return useQuery<
    Array<{
      second_category: string;
      count: number;
    }>,
    Error
  >(`toy-deals-admin`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the top deals');
    }
  });
}
